import * as Ariakit from "@ariakit/react"
import { useFetcher } from "@remix-run/react"
import { matchSorter } from "match-sorter"
import {
	type ReactNode,
	forwardRef,
	startTransition,
	useState,
	useEffect,
	useMemo,
	useCallback,
} from "react"
import { BackAwareLink } from "#app/components/back-link"
import { Icon, type IconName } from "#app/components/ui/icon"
import { useArticleRouteData } from "#app/routes/_article+/@/shared-utils.js"
import { type loader as searchLoader } from "#app/routes/_search+/search"
import { themeIcon, useThemeSwitch } from "#app/routes/resources+/theme-switch"
import { cn, useDebouncedValue } from "#app/utils/misc"
import { useRootRouteData } from "#app/utils/remix"

//
// Building blocks
//

interface CommandMenuProps extends Ariakit.DialogProps {
	open?: Ariakit.DialogStoreProps["open"]
	onOpenChange?: Ariakit.DialogStoreProps["setOpen"]
	onSearch?: Ariakit.ComboboxProviderProps["setValue"]
}

const CommandMenu = forwardRef<HTMLDivElement, CommandMenuProps>(function CommandMenu(
	{ open, onOpenChange, onSearch, ...props },
	ref,
) {
	const dialog = Ariakit.useDialogStore({ open, setOpen: onOpenChange })
	return (
		<Ariakit.Dialog
			ref={ref}
			unmountOnHide
			backdrop={
				<div className="bg-black/10 opacity-0 backdrop-blur-sm transition-all data-[enter]:opacity-100 dark:bg-black/30" />
			}
			{...props}
			store={dialog}
			className={cn(
				"fixed inset-3 z-50 m-auto flex h-fit flex-col overflow-auto rounded-xl shadow-lg",
				"mt-0 rounded-2xl bg-background p-0 text-foreground opacity-0 transition-all",
				"max-h-[480px] w-[540px] max-w-[calc(100dvw_-_1.5rem)] sm:bottom-[10vh] sm:top-[10vh] md:w-[640px]",
				"data-[enter]:opacity-100",
				props.className,
			)}
		>
			<Ariakit.ComboboxProvider
				disclosure={dialog}
				focusLoop={false}
				includesBaseElement={false}
				resetValueOnHide
				setValue={value => {
					startTransition(() => {
						onSearch?.(value)
					})
				}}
			>
				{props.children}
			</Ariakit.ComboboxProvider>
		</Ariakit.Dialog>
	)
})

interface CommandMenuInputProps extends Ariakit.ComboboxProps {}

const CommandMenuInput = forwardRef<HTMLInputElement, CommandMenuInputProps>(
	function CommandMenuInput(props, ref) {
		return (
			<div className="flex items-center gap-4 border-b border-black/10 px-4 last-of-type:border-b-0 last-of-type:border-t dark:border-white/10">
				<Ariakit.Combobox
					ref={ref}
					autoSelect="always"
					{...props}
					className={cn(
						"h-16 w-full appearance-none bg-transparent px-1 text-lg !outline-none",
						"placeholder:text-black/60 dark:placeholder:text-white/50",
						props.className,
					)}
				/>
				<Ariakit.DialogDismiss className="-mr-1 h-10 rounded-lg border border-foreground/30 px-2 font-mono text-sm text-foreground/70 -outline-offset-1">
					Esc
				</Ariakit.DialogDismiss>
			</div>
		)
	},
)

interface CommandMenuListProps extends Ariakit.ComboboxListProps {}

const CommandMenuList = forwardRef<HTMLDivElement, CommandMenuListProps>(
	function CommandMenuList(props, ref) {
		return (
			<Ariakit.ComboboxList
				ref={ref}
				{...props}
				className={cn("overflow-y-auto p-2", props.className)}
			/>
		)
	},
)

interface CommandMenuGroupProps extends Ariakit.ComboboxGroupProps {
	label?: ReactNode
}

const CommandMenuGroup = forwardRef<HTMLDivElement, CommandMenuGroupProps>(
	function CommandMenuGroup({ label, ...props }, ref) {
		return (
			<Ariakit.ComboboxGroup ref={ref} {...props} className={cn("group", props.className)}>
				{label ? (
					<Ariakit.ComboboxGroupLabel className="cursor-default px-3 py-2 text-sm font-medium opacity-50">
						{label}
					</Ariakit.ComboboxGroupLabel>
				) : null}
				{props.children}
			</Ariakit.ComboboxGroup>
		)
	},
)

interface CommandMenuItemProps extends Omit<Ariakit.ComboboxItemProps, "children"> {
	icon: IconName
	label: React.ReactNode
	description?: React.ReactNode
	extra?: React.ReactNode
	href?: string
}

const CommandMenuItem = forwardRef<HTMLDivElement, CommandMenuItemProps>(function CommandMenuItem(
	{ icon, label, description, extra, className, href, ...props },
	ref,
) {
	return (
		<Ariakit.ComboboxItem
			ref={ref}
			hideOnClick
			focusOnHover
			blurOnHoverEnd={false}
			{...props}
			className={cn(
				"flex cursor-default scroll-m-2 items-center gap-3 rounded-lg px-3 py-2 text-base !outline-none",
				"data-[active-item]:bg-black/10 dark:data-[active-item]:bg-white/10",
				className,
			)}
			render={href ? <BackAwareLink to={href} /> : undefined}
		>
			<Icon name={icon} size="md" className="flex-shrink-0" />
			{description ? (
				<span className="flex flex-col">
					<span className="">{label}</span>
					<span className="line-clamp-2 text-sm opacity-50">{description}</span>
				</span>
			) : (
				<span>{label}</span>
			)}
			{extra ? <span className="opacity-50">{extra}</span> : null}
		</Ariakit.ComboboxItem>
	)
})

//
// Searchable entries & actions
//

interface SearchableEntry {
	label: string
	icon: IconName
	action: { type: "link"; url: string } | { type: "action"; actionId: string }
	extra?: React.ReactNode
}

const articleListingPages: SearchableEntry[] = [
	{
		label: "Unread",
		icon: "file-text",
		action: { type: "link", url: "/unread" },
	},
	{
		label: "Favorites",
		icon: "star-outline",
		action: { type: "link", url: "/favorites" },
	},
	{
		label: "Finished",
		icon: "check",
		action: { type: "link", url: "/finished" },
	},
	{
		label: "Archived",
		icon: "archive",
		action: { type: "link", url: "/archived" },
	},
]

const otherPages: SearchableEntry[] = [
	{
		icon: "quote",
		label: "Highlights",
		action: { type: "link", url: "/highlights" },
	},
	{
		icon: "globe",
		label: "Discover",
		action: { type: "link", url: "/discover" },
	},
	{
		icon: "hashtag",
		label: "Manage labels",
		action: { type: "link", url: "/labels" },
	},
]

const appActions: SearchableEntry[] = [
	{
		icon: "plus",
		label: "Add Article",
		action: { type: "link", url: "/add" },
	},
]

const themeActions: SearchableEntry[] = [
	{
		icon: themeIcon.light,
		label: "Light Theme",
		action: { type: "action", actionId: "themeLight" },
	},
	{
		icon: themeIcon.dark,
		label: "Dark Theme",
		action: { type: "action", actionId: "themeDark" },
	},
	{
		icon: themeIcon.system,
		label: "System Theme",
		action: { type: "action", actionId: "themeSystem" },
	},
]

type SearchSection = [string, SearchableEntry[]]

function labelsAsSearchableEntries(labels: string[] | null): SearchableEntry[] {
	return (labels ?? []).map(label => ({
		icon: "hashtag",
		label: `#${label}`,
		action: { type: "link", url: `/label/${label}` },
		extra: "Label",
	}))
}

function searchFn(searchQuery: string, searchSections: SearchSection[]): SearchSection[] {
	if (!searchQuery) {
		return searchSections.filter(([, entries]) => entries.length > 0)
	}

	return searchSections
		.map(
			([section, entries]): SearchSection => [
				section,
				matchSorter(entries, searchQuery, { keys: ["label"] }),
			],
		)
		.filter(([, entries]) => entries.length > 0)
}

const EMPTY_SEARCH_RESULTS = { articles: [], highlights: [], highlightNotes: [] }

function useServerSearch({ searchQuery }: { searchQuery: string }) {
	const fetcher = useFetcher<typeof searchLoader>()
	const performSearchRequest = fetcher.load
	const data = fetcher.state === "idle" ? fetcher.data : undefined
	const [results, setResults] = useState<typeof data>(undefined)

	useEffect(() => {
		if (searchQuery.length < 3) return
		performSearchRequest(`/search?q=${searchQuery}`)
	}, [searchQuery, performSearchRequest])

	useEffect(() => {
		if (fetcher.state !== "idle") return
		setResults(data)
	}, [fetcher.state, data])

	return results ?? EMPTY_SEARCH_RESULTS
}

function useCommandMenuState() {
	const [open, setOpen] = useState(false)
	const [rawSearchValue, setSearchValue] = useState("")
	const searchQuery = useDebouncedValue(rawSearchValue, 300)

	useEffect(() => {
		setSearchValue("")
	}, [open])

	useEffect(() => {
		function handleKeyEvent(event: KeyboardEvent) {
			const isCmdMenuModifier =
				(event.metaKey || event.ctrlKey) && !event.shiftKey && !event.altKey
			if (event.key === "k" && isCmdMenuModifier) {
				setOpen(true)
			}
		}
		document.addEventListener("keydown", handleKeyEvent)
		return () => {
			document.removeEventListener("keydown", handleKeyEvent)
		}
	}, [])

	return { open, setOpen, searchQuery, setSearchValue }
}

function useThemeSwitchAction() {
	const switchTheme = useThemeSwitch()
	return useCallback(
		function handleAction(actionId: string) {
			if (actionId === "themeLight") {
				switchTheme("light")
				return true
			}
			if (actionId === "themeDark") {
				switchTheme("dark")
				return true
			}
			if (actionId === "themeSystem") {
				switchTheme("system")
				return true
			}
			return false
		},
		[switchTheme],
	)
}

//
// Buttons to trigger the command menu
//

function SmallCommandMenuButton({
	setOpen,
	className,
}: {
	setOpen: (open: boolean) => void
	className?: string
}) {
	return (
		<Ariakit.TooltipProvider>
			<Ariakit.TooltipAnchor
				className={cn("btn btn-ghost btn-icon flex-shrink-0", className)}
				render={
					<button
						type="button"
						onClick={() => setOpen(true)}
						aria-label="Open command menu"
					/>
				}
			>
				<Icon name="lightning-bolt" size="md">
					<span className="sr-only">Command menu</span>
				</Icon>
			</Ariakit.TooltipAnchor>
			<Ariakit.Tooltip className="z-50 overflow-hidden rounded-md border bg-popover px-3 py-1.5 text-sm text-popover-foreground shadow-md animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2">
				Open command menu
			</Ariakit.Tooltip>
		</Ariakit.TooltipProvider>
	)
}

function LargeCommandMenuButton({
	setOpen,
	className,
}: {
	setOpen: (open: boolean) => void
	className?: string
}) {
	return (
		<Ariakit.TooltipProvider>
			<Ariakit.TooltipAnchor
				className={cn(
					"btn btn-secondary border border-secondary !outline-none hover:border-primary focus:border-primary",
					"group flex gap-2 !whitespace-nowrap rounded-full px-4 py-2 transition-colors",
					className,
				)}
				render={
					<button
						type="button"
						onClick={() => setOpen(true)}
						aria-label="Open command menu"
					/>
				}
			>
				<Icon name="lightning-bolt" size="md" className="flex-shrink-0" />
				<span className="-mr-1 flex items-center rounded border border-foreground/10 px-1 font-mono tracking-wider -outline-offset-1">
					<span className="text-[120%]">⌘</span>
					<span className="text-[100%]">K</span>
				</span>
			</Ariakit.TooltipAnchor>
			<Ariakit.Tooltip className="z-50 overflow-hidden rounded-md border bg-popover px-3 py-1.5 text-sm text-popover-foreground shadow-md animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2">
				Open command menu
			</Ariakit.Tooltip>
		</Ariakit.TooltipProvider>
	)
}

//
// App-wide command menu
//

function AppCommandMenu() {
	const { labels } = useRootRouteData()
	const { open, setOpen, searchQuery, setSearchValue } = useCommandMenuState()
	const matchEntries = useMemo(
		() =>
			searchFn(searchQuery, [
				["Article listings", articleListingPages],
				["Other pages", otherPages],
				["App actions", appActions],
				["Theme settings", themeActions],
				["Labels", labelsAsSearchableEntries(labels)],
			]),
		[searchQuery, labels],
	)
	const searchResults = useServerSearch({ searchQuery })

	const handleThemeAction = useThemeSwitchAction()
	function handleAction(actionId: string) {
		handleThemeAction(actionId)
	}

	return (
		<>
			<LargeCommandMenuButton setOpen={setOpen} className="max-sm:!hidden" />
			<SmallCommandMenuButton
				setOpen={setOpen}
				className="btn-secondary rounded-full border border-secondary !outline-none hover:border-primary focus:border-primary sm:!hidden"
			/>
			<CommandMenu
				aria-label="Command Menu"
				open={open}
				onOpenChange={setOpen}
				onSearch={setSearchValue}
			>
				<CommandMenuInput placeholder="Search articles or navigate around…" />
				<CommandMenuList>
					{matchEntries.map(([group, items]) => (
						<CommandMenuGroup key={group} label={group}>
							{items.map(({ action, ...item }) =>
								action.type === "link" ? (
									<CommandMenuItem
										key={item.label}
										id={item.label}
										{...item}
										href={action.url}
									/>
								) : (
									<CommandMenuItem
										key={item.label}
										id={item.label}
										{...item}
										onClick={() => handleAction(action.actionId)}
									/>
								),
							)}
						</CommandMenuGroup>
					))}

					{searchResults.articles.length > 0 ? (
						<CommandMenuGroup label="Articles">
							{searchResults.articles.map(article => (
								<CommandMenuItem
									key={article.id}
									id={article.id}
									icon="file-text"
									label={article.title}
									href={`/article/${article.id}`}
								/>
							))}
						</CommandMenuGroup>
					) : null}

					{searchResults.highlights.length > 0 ? (
						<CommandMenuGroup label="Highlights">
							{searchResults.highlights.map(highlight => (
								<CommandMenuItem
									key={highlight.id}
									id={highlight.id}
									icon="quote"
									label={highlight.userArticle.title}
									description={highlight.text}
									href={`/article/${highlight.userArticle.id}#highlight-${highlight.id}`}
								/>
							))}
						</CommandMenuGroup>
					) : null}

					{searchResults.highlightNotes.length > 0 ? (
						<CommandMenuGroup label="Highlight notes">
							{searchResults.highlightNotes.map(highlight => (
								<CommandMenuItem
									key={highlight.id}
									id={highlight.id}
									icon="pencil-1"
									label={highlight.userArticle.title}
									description={highlight.note ?? highlight.text}
									href={`/article/${highlight.userArticle.id}#highlight-${highlight.id}`}
								/>
							))}
						</CommandMenuGroup>
					) : null}

					{searchQuery.length > 0 ? (
						<CommandMenuGroup label="Search in articles and highlights">
							<CommandMenuItem
								id="search"
								icon="magnifying-glass"
								label={
									<>
										Search for <q className="font-semibold">{searchQuery}</q>
									</>
								}
								href={`/search?q=${searchQuery}`}
							/>
						</CommandMenuGroup>
					) : null}
				</CommandMenuList>
			</CommandMenu>
		</>
	)
}

//
// Article-specific command menu
//

function getArticleActions(article: {
	favoritedAt: string | null
	archivedAt: string | null
	readAt: string | null
}): SearchableEntry[] {
	return [
		{
			icon: article.favoritedAt ? "star-outline" : "star-filled",
			label: article.favoritedAt ? "Remove from favorites" : "Add to favorites",
			action: { type: "action", actionId: "favorite" },
		},
		{
			icon: "check",
			label: article.readAt ? "Mark as unread" : "Mark as read",
			action: { type: "action", actionId: "read" },
		},
		{
			icon: "archive",
			label: article.archivedAt ? "Unarchive" : "Archive",
			action: { type: "action", actionId: "archive" },
		},
	]
}

const fontSize: SearchableEntry[] = [
	{
		icon: "font-size",
		label: "Small font size",
		action: { type: "action", actionId: "prefs-fs-sm" },
	},
	{
		icon: "font-size",
		label: "Medium font size",
		action: { type: "action", actionId: "prefs-fs-md" },
	},
	{
		icon: "font-size",
		label: "Large font size",
		action: { type: "action", actionId: "prefs-fs-lg" },
	},
	{
		icon: "font-size",
		label: "Huge font size",
		action: { type: "action", actionId: "prefs-fs-xl" },
	},
]

const fontFamily: SearchableEntry[] = [
	{
		icon: "font-family",
		label: "Sans-serif font",
		action: { type: "action", actionId: "prefs-ff-sans" },
	},
	{
		icon: "font-family",
		label: "Serif font",
		action: { type: "action", actionId: "prefs-ff-serif" },
	},
	{
		icon: "font-family",
		label: "Dyslexic font",
		action: { type: "action", actionId: "prefs-ff-dyslexic" },
	},
]

function ArticleCommandMenu() {
	const { article } = useArticleRouteData()
	const { open, setOpen, searchQuery, setSearchValue } = useCommandMenuState()
	const matchEntries = useMemo(
		() =>
			searchFn(searchQuery, [
				["Article actions", getArticleActions(article)],
				["Text size", fontSize],
				["Font", fontFamily],
				["Theme settings", themeActions],
				["App navigation", [...articleListingPages, ...otherPages]],
			]),
		[searchQuery, article],
	)

	const handleThemeAction = useThemeSwitchAction()
	const fetcher = useFetcher()

	function handleAction(actionId: string) {
		if (handleThemeAction(actionId)) {
			return
		}

		if (actionId === "favorite" || actionId === "archive" || actionId === "read") {
			fetcher.submit(null, { method: "POST", action: `/article/${article.id}/${actionId}` })
			return
		}

		if (actionId.startsWith("prefs-")) {
			const [name, value] = actionId.split("-").slice(1)
			const formData = new FormData()
			formData.append(name, value)
			fetcher.submit(formData, { method: "PUT", action: "/settings/preferences" })
			return
		}
	}

	return (
		<>
			<SmallCommandMenuButton className="btn-sm" setOpen={setOpen} />
			<CommandMenu
				aria-label="Command Menu"
				open={open}
				onOpenChange={setOpen}
				onSearch={setSearchValue}
			>
				<CommandMenuInput placeholder="Search, browse, or change the current article…" />
				<CommandMenuList>
					{matchEntries.map(([group, items]) => (
						<CommandMenuGroup key={group} label={group}>
							{items.map(({ action, ...item }) =>
								action.type === "link" ? (
									<CommandMenuItem
										key={item.label}
										id={item.label}
										{...item}
										href={action.url}
									/>
								) : (
									<CommandMenuItem
										key={item.label}
										id={item.label}
										{...item}
										onClick={() => handleAction(action.actionId)}
									/>
								),
							)}
						</CommandMenuGroup>
					))}
				</CommandMenuList>
			</CommandMenu>
		</>
	)
}

export { AppCommandMenu, ArticleCommandMenu }
