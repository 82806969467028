import { parseWithZod } from "@conform-to/zod"
import { invariantResponse } from "@epic-web/invariant"
import { json, type ActionFunctionArgs } from "@remix-run/node"
import { useFetcher, useFetchers } from "@remix-run/react"
import { useCallback } from "react"
import { z } from "zod"
import { useHints } from "#app/utils/client-hints.tsx"
import { useRequestInfo, useRootRouteData } from "#app/utils/remix"
import { setTheme } from "#app/utils/theme.server.ts"

const ThemeFormSchema = z.object({
	theme: z.enum(["system", "light", "dark"]),
})

export async function action({ request }: ActionFunctionArgs) {
	const formData = await request.formData()
	const submission = parseWithZod(formData, {
		schema: ThemeFormSchema,
	})

	invariantResponse(submission.status === "success", "Invalid theme received")

	const { theme } = submission.value

	const responseInit = {
		headers: { "set-cookie": setTheme(theme) },
	}
	return json({ result: submission.reply() }, responseInit)
}

export function useThemeSwitch() {
	const fetcher = useFetcher<typeof action>()
	return useCallback(
		function handleSubmit(newMode: "light" | "dark" | "system") {
			const formData = new FormData()
			formData.set("theme", newMode)
			fetcher.submit(formData, { method: "POST", action: "/resources/theme-switch" })
		},
		[fetcher],
	)
}

export function ThemeSwitch({
	children,
}: {
	children: (args: { mode: "light" | "dark" | "system"; onClick: () => void }) => React.ReactNode
}) {
	const data = useRootRouteData()
	const { theme: userPreference } = data.requestInfo.userPrefs

	const optimisticMode = useOptimisticThemeMode()
	const mode = optimisticMode ?? userPreference ?? "system"
	const nextMode = mode === "system" ? "light" : mode === "light" ? "dark" : "system"

	const switchTheme = useThemeSwitch()

	function handleSubmit() {
		switchTheme(nextMode)
	}

	return children({ mode, onClick: handleSubmit })
}

/**
 * If the user's changing their theme mode preference, this will return the
 * value it's being changed to.
 */
function useOptimisticThemeMode() {
	const fetchers = useFetchers()
	const themeFetcher = fetchers.find(f => f.formAction === "/resources/theme-switch")

	if (themeFetcher && themeFetcher.formData) {
		const submission = parseWithZod(themeFetcher.formData, {
			schema: ThemeFormSchema,
		})

		if (submission.status === "success") {
			return submission.value.theme
		}
	}
}

/**
 * @returns the user's theme preference, or the client hint theme if the user
 * has not set a preference.
 */
export function useTheme() {
	const hints = useHints()
	const requestInfo = useRequestInfo()
	const optimisticMode = useOptimisticThemeMode()
	if (optimisticMode) {
		return optimisticMode === "system" ? hints.theme : optimisticMode
	}
	return requestInfo.userPrefs.theme ?? hints.theme
}

export const themeIcon = {
	light: "sun",
	dark: "moon",
	system: "half",
} as const

export const themeLabel = {
	light: "Light",
	dark: "Dark",
	system: "System",
}
